import {
	GetUsersSortField,
	Role,
	RoleNames,
	User,
	UserOrganizationsRoleStatus,
	UserStatus,
} from '@lh/eng-platform-organization-service-rest-client';

import { t } from 'i18n';
import { CurrentUser } from 'types';
import { UserTableSortField } from '../../../../generated/graphql';
import { AvatarName } from '../../avatar/AvatarName';
import { TableColumn, TableColumnOption } from '../DataTable';
import { EmailLink } from '../columnComponents/EmailLink';
import { Kebab } from '../columnComponents/Kebab';
import { StatusColumn } from '../columnComponents/StatusColumn';

export type TableData = {
	firstName: string;
	lastName: string;
	email: string;
	id: string;
	role: string;
	avatarUrl?: string;
};
const columns = (
	columnOptions: TableColumnOption<TableData>[],
	currentUser: Partial<CurrentUser>
): TableColumn<TableData>[] => {
	return [
		{
			propertyName: 'name',
			headerDisplay: t('web.shared.name'),
			sortable: true,
			sortProperty: GetUsersSortField.LastName,
			minWidth: '290px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as TableData;
				return (
					<AvatarName
						firstName={rowDataType.firstName}
						lastName={rowDataType.lastName}
						avatarUrl={rowDataType.avatarUrl}
					/>
				);
			},
		},
		{
			propertyName: 'email',
			headerDisplay: t('web.shared.email'),
			sortable: true,
			minWidth: '290px',
			sortProperty: GetUsersSortField.Email,
			rowColumnComponent: ({ value }) => {
				return <EmailLink emailAddress={value || ''} />;
			},
		},
		{
			propertyName: 'role',
			headerDisplay: t('web.shared.role'),
			sortProperty: UserTableSortField.UserRoleName,
			minWidth: '206px',
		},
		{
			propertyName: 'statusInOrg',
			headerDisplay: t('web.shared.status'),
			minWidth: '150px',
			rowColumnComponent: ({ value }) => {
				return <StatusColumn status={value} />;
			},
		},
		{
			propertyName: '',
			sortable: false,
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ row, column }) => {
				const rowDataType = row as TableData;
				const isLinusAdmin = !!currentUser.roles?.find((role) =>
					[
						RoleNames.LinusAdministrator.toString(),
						RoleNames.LinusLimitedAdministrator.toString(),
					].includes(role.roleName)
				);
				// We don't want the logged-in user to be able to deactivate/lower their role
				const isCurrentUser = rowDataType.id === currentUser.id;

				return isCurrentUser && !isLinusAdmin ? (
					<></>
				) : (
					<Kebab row={row} column={column} />
				);
			},
			options: columnOptions,
		},
	];
};

type UserWithRoles = User & {
	role: string;
	statusInOrg?: UserOrganizationsRoleStatus | UserStatus;
};

const mapSearchResultsToTable = (
	currentUser: CurrentUser,
	orgRoles: Role[],
	data?: User[]
): UserWithRoles[] => {
	if (!data) return [];

	return data.map((x) => {
		const orgRole = x.roles.find(
			(role) => role.organizationId === currentUser.organizationId
		);
		const roleName = (orgRoles || []).find(
			(role) => role.id === orgRole?.roleId
		);

		return {
			...x,
			role: t(roleName?.displayKey || '') || '',
			statusInOrg:
				x.status === UserStatus.Invited &&
				orgRole?.status !== UserOrganizationsRoleStatus.Deactivated
					? x.status
					: orgRole?.status,
		};
	});
};

export { columns, mapSearchResultsToTable };
