import { Typography } from '@lh/eng-web-mosaic-common';
import styled, { css } from 'styled-components';

import { ScoreType } from '../../types';
import { Responses } from '../Responses';

export const TrialsTestIds = {
	container: 'trials-container',
};

type TrialsProps = {
	label: string;
	score: string;
	title: string;
	type?: ScoreType;
};

export function Trials({
	label,
	score,
	title,
	type = 'unknown',
}: Readonly<TrialsProps>) {
	return (
		<TrialsContainer data-testid={TrialsTestIds.container}>
			<Typography.P1 weight='600'>{title}</Typography.P1>
			<Responses label={label} score={score} type={type} />
		</TrialsContainer>
	);
}

const TrialsContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);
