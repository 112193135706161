import { theme, Typography } from '@lh/eng-web-mosaic-common';
import styled, { css } from 'styled-components';

import { ScoreType } from '../../types';

const TYPE_TO_COLOR: Record<ScoreType, string> = {
	impaired: theme.colors.orange_lighter,
	mixed: theme.colors.yellow_lighter,
	'not-impaired': theme.colors.green_lighter,
	unanalyzable: theme.colors.gray_80,
	unknown: theme.colors.gray_80,
};

export const Chip = styled.div<{ $type: ScoreType }>(
	({ theme: { spacing }, $type }) => css`
		display: flex;
		width: 64px;
		padding: ${spacing.xs};
		justify-content: center;
		align-items: center;
		gap: ${spacing.xxs};
		border-radius: ${spacing.sm};
		background: ${TYPE_TO_COLOR[$type]};
	`
);

export const Text = styled(Typography.P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyText};
		text-align: center;
	`
);
