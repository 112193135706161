import { MetricItem } from '@lh/eng-platform-battery-service-rest-client';

import { removeQuotesAndBrackets } from 'utils/stringUtils';

import {
	ImmediateRecallPerformance,
	ImmediateRecallTrial,
} from './ImmediateRecall.types';
import { createImmediateRecallScore } from './createImmediateRecallScore';

function processPrompt(prompt: string) {
	if (!prompt) {
		return '--';
	}

	return removeQuotesAndBrackets(prompt)
		.split(',')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(', ');
}

export function parseImmediateRecallMetrics(metricItems: MetricItem[]) {
	const performance: ImmediateRecallPerformance = {
		impaired: -1,
		percentile: null,
		rcr: false,
		score: null,
	};
	let rcr = false;
	const scores: number[] = [];
	const trials: ImmediateRecallTrial[] = [
		{
			impaired: -1,
			prompt: '',
			score: '',
		},
		{
			impaired: -1,
			prompt: '',
			score: '',
		},
	];

	metricItems.forEach((metricItem) => {
		if (metricItem.key === 'pvlt6_trial1_impairment_classification') {
			if (metricItem.value === 'IMPAIRED') {
				trials[0].impaired = 1;
			} else {
				trials[0].impaired = 0;
			}
		}

		if (metricItem.key === 'pvlt_recall_score_trial_1') {
			const score = Number(metricItem.value);
			scores[0] = score;
			trials[0].rcr = isNaN(score);
			trials[0].score = createImmediateRecallScore(
				isNaN(score) ? '--' : score,
				'trial'
			);
		}

		if (metricItem.key === 'pvlt6_trial2_impairment_classification') {
			if (metricItem.value === 'IMPAIRED') {
				trials[1].impaired = 1;
			} else {
				trials[1].impaired = 0;
			}
		}

		if (metricItem.key === 'pvlt_recall_score_trial_2') {
			const score = Number(metricItem.value);
			scores[1] = score;
			trials[1].rcr = isNaN(score);
			trials[1].score = createImmediateRecallScore(
				isNaN(score) ? '--' : score,
				'trial'
			);
		}

		if (metricItem.key === 'pvlt_expected_words_trial_1') {
			trials[0].prompt = processPrompt(metricItem.value);
		}

		if (metricItem.key === 'pvlt_expected_words_trial_2') {
			trials[1].prompt = processPrompt(metricItem.value);
		}

		if (metricItem.key === 'pvlt6_impairment_classification') {
			if (metricItem.value === 'IMPAIRED') {
				performance.impaired = 1;
			} else {
				performance.impaired = 0;
			}
		}

		if (metricItem.key === 'pvlt6_combined_percentile') {
			if (metricItem.value !== '-1') {
				const value = Number(metricItem.value);

				if (!isNaN(value)) {
					performance.percentile = value;
				}
			}
		}

		if (metricItem.key === 'pvlt_recall_combined_score') {
			performance.score = isNaN(Number(metricItem.value))
				? null
				: createImmediateRecallScore(metricItem.value);
		}

		if (metricItem.key === 'rcr_flags' && metricItem.value !== '[]') {
			rcr = true;
		}
	});

	if (performance.score === null) {
		const combined = scores[0] + scores[1];
		if (!isNaN(combined)) {
			performance.score = createImmediateRecallScore(combined);
		}
	}

	if (rcr) {
		performance.rcr = true;
		performance.score = createImmediateRecallScore('--');
		trials[0].rcr = true;
		trials[0].score = createImmediateRecallScore('--', 'standalone');
		trials[1].rcr = true;
		trials[1].score = createImmediateRecallScore('--', 'standalone');
	}

	return { performance, trials };
}
