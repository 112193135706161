import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Flex, Stack } from '@mantine/core';

import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem';

import { ScoreCell } from './DomainScore.style';
import { DomainStatus } from './DomainScore.types';

type DomainScoreProps = {
	description?: string;
	status: DomainStatus;
	title: string;
	value: number;
};

export function DomainScore({
	description,
	status,
	title,
	value,
}: Readonly<DomainScoreProps>) {
	return (
		<Flex flex={1} gap={4}>
			<ScoreCell $status={status}>
				{status === 'RED' && value > 0 && (
					<Icon
						color={theme.color.alertError}
						icon={icons.ArrowFlag}
						height={24}
						width={24}
					/>
				)}
				<Typography.H4
					color={
						value > 0
							? theme.color.bodyText
							: theme.color.bodyTextSecondary
					}
				>
					{value}
				</Typography.H4>
			</ScoreCell>
			<Stack gap={4} justify='center'>
				<Typography.P1>{title}</Typography.P1>
				{description && (
					<Typography.P2 weight='500'>{description}</Typography.P2>
				)}
			</Stack>
		</Flex>
	);
}
