const IMMEDIATE_RECALL_MAX_TOTAL_SCORE = 12;
const IMMEDIATE_RECALL_MAX_TRIAL_SCORE = 6;

export function createImmediateRecallScore(
	value: number | string,
	type: 'total' | 'trial' | 'standalone' = 'total'
) {
	if (type === 'standalone') {
		return value.toString();
	}

	const max =
		type === 'total'
			? IMMEDIATE_RECALL_MAX_TOTAL_SCORE
			: IMMEDIATE_RECALL_MAX_TRIAL_SCORE;
	return `${value}/${max}`;
}
