import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { AudioPlaybackCard, ReportInfoPanel } from 'components/mosaic';

import { ImmediateRecallBreakdown } from './ImmediateRecallBreakdown';
import { ImmediateRecallScore } from './ImmediateRecallScore';

import {
	CaptionFaded,
	Container,
	Header,
	Grid,
	Section,
} from './ImmediateRecall.style';
import {
	ImmediateRecallPerformance,
	ImmediateRecallTrial,
} from './ImmediateRecall.types';

export type ImmediateRecallLayoutProps = {
	performance: ImmediateRecallPerformance;
	trials: ImmediateRecallTrial[];
	urls: string[];
};

export function ImmediateRecallLayout({
	performance,
	trials,
	urls,
}: Readonly<ImmediateRecallLayoutProps>) {
	const { t } = useTranslation();

	return (
		<Container>
			<ReportInfoPanel
				title={t('web.report.pvltImmediateReport.immediateRecall')}
				tooltipText={
					t('web.report.pvltImmediateReport.description', {
						returnObjects: true,
					}) as string[]
				}
			>
				<Flex>
					<ImmediateRecallScore performance={performance} />
					<ImmediateRecallBreakdown trials={trials} />
				</Flex>
			</ReportInfoPanel>
			<Section>
				<Header>{t('web.shared.recordings')}</Header>
				<Grid>
					<AudioPlaybackCard
						audioSource={urls[0]}
						cardTitle={t(
							'web.report.pvltImmediateReport.immediateRecall'
						)}
					>
						{t('web.report.pvltImmediateReport.prompt')}{' '}
						{trials[0].prompt}
					</AudioPlaybackCard>
					<AudioPlaybackCard
						audioSource={urls[1]}
						cardTitle={t(
							'web.report.pvltImmediateReport.immediateRecallRepeat'
						)}
					>
						{t('web.report.pvltImmediateReport.prompt')}{' '}
						{trials[1].prompt}
					</AudioPlaybackCard>
				</Grid>
			</Section>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</Container>
	);
}
