import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { ImmediateRecallLayout } from './ImmediateRecallLayout';
import { useImmediateRecallData } from './useImmediateRecallData';

type ImmediateRecallReportProps = {
	segmentResult: DeepSegmentResult;
};

export const ImmediateRecallReport = ({
	segmentResult,
}: Readonly<ImmediateRecallReportProps>) => {
	const { performance, trials, urls } = useImmediateRecallData(segmentResult);

	return (
		<ImmediateRecallLayout
			performance={performance}
			trials={trials}
			urls={urls}
		/>
	);
};
