import {
	GetUsersSortField,
	OperationToken,
} from '@lh/eng-platform-organization-service-rest-client';

import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
	AnalyticsAction,
	sendDebouncedEventData,
	sendEventData,
} from 'analytics';
import { useRolesQuery } from 'api/organization';
import { useUsers } from 'api/user';
import { icons } from '../../../enums/icons';
import { SortDir, UserTableSearchField } from '../../../generated/graphql';
import { UserContext } from '../../context/UserContext';
import { LinusPaginatedDataTable } from '../../shared/DataTable/PaginatedDataTable';
import {
	TableData,
	columns,
	mapSearchResultsToTable,
} from '../../shared/DataTable/schemas/teamSchema';
import { Header } from '../../shared/Header';
import { LinusModal } from '../../shared/LinusModal';
import { useActiveTableHeader } from '../../shared/hooks/useActiveTableHeader';
import { AddNewUserForm } from '../AddNewUserForm';
import { EditUserForm } from '../EditUserForm';

import { getUsersFilterOptions, getUsersSortOptions } from './Team.utils';
import { CurrentUser } from 'types';

export type SortProps = {
	dir: SortDir | null;
	prop: GetUsersSortField | null;
};

export type SearchProps = {
	value: string | null;
	prop: UserTableSearchField;
};

const PAGE_SIZE = 10;

const ROWS_PER_PAGE = 10;

export const Team = (): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	const tableRef = useRef<{ clearSearchInput(): void }>(null);
	const isSelectedMemberThirdParty = useRef(false);

	const [page, setPage] = useState(1);

	const [sort, setSort] = useState<SortProps | null>(null);
	const [search, setSearch] = useState('');
	const { activeHeader, setActiveHeader } = useActiveTableHeader();

	const { data: users, isPending: areUsersPending } = useUsers({
		organizationId: currentUser.organizationId,
		page: page - 1,
		pageSize: ROWS_PER_PAGE,
		...getUsersFilterOptions(search),
		...getUsersSortOptions(sort),
	});

	const { data: organizationRoles } = useRolesQuery(
		currentUser.organizationId
	);

	/**
	 * Logs
	 */
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedOrganization });
	}, []);

	/**
	 * Reset data when organization changes
	 */
	useEffect(() => {
		resetData();
	}, [currentUser.organizationId]);

	useEffect(() => {
		if (search && !areUsersPending) {
			sendDebouncedEventData({
				eventType: AnalyticsAction.SearchingTable,
				eventProperties: {
					hasResults: !!users?.results.length,
				},
			});
		}
	}, [search, users?.results.length, areUsersPending]);

	// Modal controls
	const [isAddTeamMemberOpen, setIsAddTeamMemberOpen] = useState(false);
	const [isEditTeamMemberOpen, setIsEditTeamMemberOpen] = useState(false);
	const [selectedMember, setSelectedMember] = useState('');
	const toggleAddTeamMemberModal = (): void => {
		setIsAddTeamMemberOpen(!isAddTeamMemberOpen);
	};
	const closeEditTeamMember = (): void => {
		setIsEditTeamMemberOpen(!isEditTeamMemberOpen);
		setSelectedMember('');
		isSelectedMemberThirdParty.current = false;
	};
	const viewEditUserModal = ({ id }: TableData) => {
		const selectedUser = users?.results.find((user) => user.id === id);

		setSelectedMember(id);

		isSelectedMemberThirdParty.current =
			selectedUser?.isThirdPartyManaged ?? false;

		setIsEditTeamMemberOpen(!isEditTeamMemberOpen);
	};

	const closeModals = (): void => {
		setIsEditTeamMemberOpen(false);
		setIsAddTeamMemberOpen(false);
		setSelectedMember('');
	};

	const resetData = (): void => {
		setPage(1);
		setSort(null);
		setSearch('');
		// This should reset the headers to default (visually)
		setActiveHeader(undefined);
		tableRef.current?.clearSearchInput();
	};

	const handleModalFinished = (): void => {
		closeModals();
		resetData();
	};

	const onSort = (dir: SortDir | undefined, prop: string) => {
		if (!dir) {
			setSort(null);
		} else {
			setActiveHeader(prop);
			setSort({
				dir,
				prop: prop as GetUsersSortField,
			});
		}
	};

	const partialUser: Partial<CurrentUser> = useMemo(() => {
		return {
			id: currentUser.id,
			roles: currentUser.roles,
		};
	}, [currentUser.id, currentUser.roles]);

	const tableColumns = columns(
		[
			{
				key: 'viewUser',
				value: t`web.team.viewUserButton`,
				callback: viewEditUserModal,
				operations: [OperationToken.ViewUser],
			},
		],
		partialUser
	);

	const tableData = useMemo(() => {
		return mapSearchResultsToTable(
			currentUser,
			organizationRoles || [],
			users?.results
		);
	}, [currentUser, organizationRoles, users?.results]);

	const hasInitialData = useRef(false);

	if (users?.results.length) hasInitialData.current = true;

	const count = users?.results.length || 0;
	const total = users?.totalCount || 0;

	const notFoundTitle = t('web.shared.search.noMatchFound', {
		entity: t('web.team.table.users').toLowerCase(),
	});
	const notFoundSubtitle = t('web.shared.search.addNewOrChangeSpelling', {
		entity: t('web.team.table.user').toLowerCase(),
	});

	function handleSearchChange(predicate: string) {
		if (predicate !== search) {
			setPage(1);
		}
		setSearch(predicate);
	}

	return (
		<>
			<Header />
			<LinusPaginatedDataTable
				_ref={tableRef}
				title={t`web.team.table.title`}
				columns={tableColumns}
				tableData={tableData}
				rowsPerPage={PAGE_SIZE}
				count={count}
				total={total}
				currentPage={page}
				setCurrentPage={(pageNumber: number) => setPage(pageNumber)}
				loading={areUsersPending}
				hasInitialData={false}
				onSort={onSort}
				onFilter={handleSearchChange}
				operations={[OperationToken.InviteUser]}
				onHeaderButtonClick={toggleAddTeamMemberModal}
				buttonText={t`web.team.addNewUserButton`}
				buttonIcon={icons.AddUserOutlined}
				noDataIcon={icons.NoDataUser}
				searchBarPlaceholder={t`web.shared.search.byFirstOrLastName`}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				dataId='add-new-user'
				activeHeader={activeHeader}
			/>

			<AnimatePresence>
				{isAddTeamMemberOpen && (
					<LinusModal
						onClose={toggleAddTeamMemberModal}
						titleIcon={icons.AddUserSolid}
						titleIconColor={theme.color.iconAddUserSolid}
						title={t`web.team.addNewUserModal.title`}
						subTitle={t`web.team.addNewUserModal.description`}
					>
						<AddNewUserForm
							onCancel={toggleAddTeamMemberModal}
							onFinish={handleModalFinished}
						/>
					</LinusModal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{isEditTeamMemberOpen && (
					<LinusModal
						onClose={closeEditTeamMember}
						titleIcon={icons.UserSolid}
						titleIconColor={theme.color.iconAddUserSolid}
						title={t`web.team.editUserModal.title`}
						isThirdPartyManaged={isSelectedMemberThirdParty.current}
					>
						<EditUserForm
							userId={selectedMember}
							onCancel={closeEditTeamMember}
							onFinish={handleModalFinished}
						/>
					</LinusModal>
				)}
			</AnimatePresence>
		</>
	);
};
