import { Typography } from '@lh/eng-web-mosaic-common';
import styled, { css } from 'styled-components';

import { ScoreChip } from '../../atoms/ScoreChip';
import { ScoreType } from '../../types';

export const ResponsesTestIds = {
	container: 'responses-container',
};

type ResponsesProps = {
	label: string;
	score: string;
	type?: ScoreType;
};

export function Responses({
	label,
	score,
	type = 'unknown',
}: Readonly<ResponsesProps>) {
	return (
		<ResponsesContainer data-testid={ResponsesTestIds.container}>
			<ScoreChip type={type}>{score}</ScoreChip>
			<Typography.P2>{label}</Typography.P2>
		</ResponsesContainer>
	);
}

const ResponsesContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
		align-items: center;
	`
);
