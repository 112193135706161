import { useTranslation } from 'react-i18next';

import { ScoreType, Trials } from 'components/mosaic';

import {
	BreakdownTitle,
	TrialsContainer,
	TrialsDivider,
} from './ImmediateRecall.style';
import { ImmediateRecallTrial } from './ImmediateRecall.types';

const IMPAIRED_TO_SCORE_TYPE: Record<number, ScoreType> = {
	[-1]: 'unknown',
	[0]: 'not-impaired',
	[1]: 'impaired',
};

type ImmediateRecallBreakdownProps = {
	trials: ImmediateRecallTrial[];
};

export function ImmediateRecallBreakdown({
	trials,
}: Readonly<ImmediateRecallBreakdownProps>) {
	const { t } = useTranslation();

	return (
		<TrialsContainer>
			<BreakdownTitle>
				{t('web.report.pvltImmediateReport.breakdown')}
			</BreakdownTitle>
			<Trials
				label={
					trials[0].rcr
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				score={trials[0].score}
				title={t('web.report.pvltImmediateReport.trials1')}
				type={
					trials[0].rcr
						? 'unanalyzable'
						: IMPAIRED_TO_SCORE_TYPE[trials[0].impaired]
				}
			/>
			<TrialsDivider />
			<Trials
				label={
					trials[1].rcr
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				score={trials[1].score}
				title={t('web.report.pvltImmediateReport.trials2')}
				type={
					trials[1].rcr
						? 'unanalyzable'
						: IMPAIRED_TO_SCORE_TYPE[trials[1].impaired]
				}
			/>
		</TrialsContainer>
	);
}
