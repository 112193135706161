import { useTranslation } from 'react-i18next';

import { Trials } from 'components/mosaic';
import { LineDivider } from 'components/shared/LineDivider';

import { BreakdownTitle, ScoreBreakdownContainer } from './FluencyLayout.style';
import { FluencyScoreBreakdown } from './FluencyLayout.types';

type FluencyBreakdownProps = {
	breakdown: FluencyScoreBreakdown;
	requiresReview: boolean;
};

export function FluencyBreakdown({
	breakdown,
	requiresReview,
}: Readonly<FluencyBreakdownProps>) {
	const { t } = useTranslation();

	return (
		<ScoreBreakdownContainer data-testid='score-breakdown'>
			<BreakdownTitle data-testid='score-breakdown-title'>
				{t('web.report.uniqueResponses.breakdown')}
			</BreakdownTitle>
			<Trials
				label={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '0-15',
				})}
				score={requiresReview ? '--' : breakdown.epoch1}
			/>
			<LineDivider />
			<Trials
				label={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '16-30',
				})}
				score={requiresReview ? '--' : breakdown.epoch2}
			/>
			<LineDivider />
			<Trials
				label={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '31-45',
				})}
				score={requiresReview ? '--' : breakdown.epoch3}
			/>
			<LineDivider />
			<Trials
				label={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '46-60',
				})}
				score={requiresReview ? '--' : breakdown.epoch4}
			/>
		</ScoreBreakdownContainer>
	);
}
