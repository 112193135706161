import { ScoreType } from '../../types';

import { Chip, Text } from './ScoreChip.style';

type ScoreChipProps = {
	children: string;
	type: ScoreType;
};

export function ScoreChip({ children, type }: Readonly<ScoreChipProps>) {
	return (
		<Chip $type={type}>
			<Text weight='600'>{children}</Text>
		</Chip>
	);
}
