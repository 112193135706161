import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';

import { TabsContext } from './useTabsContext';

export type TabItem = {
	isInitLoading?: boolean;
	element?: JSX.Element;
	name: string;
};

export type TabbedLayoutProps = {
	offsetContainerRef?: React.RefObject<HTMLDivElement>;
	tabs: TabItem[];
	onTabChange?: (tabName: string) => void;
};

const TabbedLayout = ({
	offsetContainerRef,
	tabs,
	onTabChange,
}: TabbedLayoutProps): JSX.Element | null => {
	const [activeTab, setActiveTab] = useState(tabs[0]);

	const changeTab = useCallback((tabName: string) => {
		const tab = tabs.find(
			(tab) => tab.name === tabName || tab.name.includes(tabName)
		);
		if (!tab) {
			return;
		}

		setActiveTab(tab);
		onTabChange?.(tabName);
	}, []);

	const { element } = activeTab;
	if (!element) {
		return null;
	}

	return (
		<TabsContext.Provider value={changeTab}>
			<StyledContainer>
				<StyledTabMenu>
					<StyledList>
						{tabs.map(({ name }) => {
							const isTabActive = name === activeTab.name;

							return (
								<StyledTab
									$active={isTabActive}
									key={name}
									data-testid={name}
									onClick={() => {
										sendEventData({
											eventType:
												AnalyticsAction.ClickedCceTab,
											eventProperties: {
												name: name,
											},
										});

										// Scroll to start of the tab
										if (offsetContainerRef) {
											scrollToRefByOffset(
												offsetContainerRef
											);
										}

										changeTab(name);
									}}
								>
									<StyledSpan $active={isTabActive}>
										{name}
									</StyledSpan>
								</StyledTab>
							);
						})}
					</StyledList>
				</StyledTabMenu>
				<StyledWrapper>{!!element && element}</StyledWrapper>
			</StyledContainer>
		</TabsContext.Provider>
	);
};

export const scrollToRefByOffset = (
	offsetRef?: React.RefObject<HTMLDivElement>
) => {
	if (offsetRef) {
		offsetRef.current?.parentElement?.parentElement?.scrollTo({
			top: offsetRef.current?.clientHeight || 0,
			behavior: 'smooth',
		});
	}
};

export { TabbedLayout };

const StyledContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: none;
`;
const StyledTabMenu = styled.div(
	({ theme: { color } }) => `
	background: ${color.body};
	width: 100%;
	height: 57px;
	color: ${color.bodyText};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${color.menuBorder};
	position: sticky;
	top: 170px;
	z-index: 3;
`
);
const StyledList = styled.ul`
	overflow: auto;
	list-style: none;
	display: flex;
	align-items: center;
`;
type StyledTabProps = {
	$active: boolean;
};
const StyledTab = styled.li<StyledTabProps>(
	({ $active, theme: { spacing, color, colors } }) => `
	height: 100%;
	min-width: 160px;
	text-align: center;
	padding: ${spacing.md} ${spacing.sm};
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 8px 8px 0 0;
	border: 1px solid ${color.menuBorder};
	border-bottom:  ${
		$active ? `3px solid ${color.menuBorderActive}` : color.menuBorder
	};
	background: ${$active ? colors.blue_ultraLight : color.white};
	&:hover {
		background: ${color.cellHoverBg};
		cursor: pointer;
	};
	`
);
const StyledSpan = styled.span<StyledTabProps>(
	({ $active, theme: { color, weight } }) => `
	font-weight: ${$active ? weight.semi : weight.medium};
	padding: 0;
	margin: 0;
	color: ${$active ? color.bodyText : color.menuText};
	`
);
const StyledWrapper = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		height: 100%;
		z-index: 1;
		margin-top: ${spacing.lg};
		overflow-y: hidden;
	`
);
